.speechBubble {
  position:relative;
  padding: 0px 12px;
  margin: 5px 0px;
  background-color: var(--color-purple-300);
  border-radius: 2px;
  border: 1px solid var(--color-purple-base);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.speechBubble p {
  padding-left: 5px;
  color: var(--color-purple-base);
  font-size: var(--font-size-01);
  font-weight: var(--font-weight-light);
  display: inline;
  line-height: 30px;
}

.speechBubble::before,
.speechBubble::after {
  content: "\0020";
  display:block;
  position:absolute;
  top:-4px;
  left:10px;
  z-index:2;
  width: 0;
  height: 0;
  overflow:hidden;
  border: solid 5.5px transparent;
  border-top: 0;
  border-bottom-color: var(--color-purple-300);
}

.speechBubble::before {
  top:-5.5px;
  z-index:1;
  border-bottom-color: var(--color-purple-base);
}