@import '../../styles/breakpoints';

.stickySummary {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: var(--size-spacing-03) var(--size-spacing-05);
  background: var(--color-neutral-lighter);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  animation-name: slide-up;
  animation-duration: 250ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;

  @include device-medium {
    top: 0;
    bottom: unset;
    animation-name: slide-down;
  }

  @media (prefers-reduced-motion) {
    animation-name: none;
  }
}

.innerWrapper {
  max-width: var(--container-width);
  margin: auto;
  display: flex;
  align-items: center;
}

.pageSummary {
  display: none;
  flex: 2;

  @include device-medium {
    display: initial;
  }
}

.callsToAction {
  width: 100%;
  flex: 1;

  :global {
    .cp-btn {
      min-width: auto;
    }
  }

  @include device-medium {
    width: unset;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.callsToActionVariantC {
  flex: 1;
  :global {
    .cp-btn {
      min-width: auto;
    }
  }

  > :nth-child(1) {
    flex-grow: 5;
    width: fit-content;
  }

  > :nth-child(2) {
    flex-grow: 2;
    width: fit-content;
  }
}
