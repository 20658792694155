@import '../../styles/breakpoints';

.modal {
  --modal-padding: var(--size-spacing-05);
  --modal-margin: var(--size-spacing-05);
  border: none;
  border-radius: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: calc(100% - var(--modal-margin) * 2);
  max-height: calc(100% - var(--modal-margin) * 2);

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.6);
  }

  @include device-small {
    min-height: 25vmin;
    min-width: 40vmin;
  }

  &.polyfilled {
    // https://github.com/GoogleChrome/dialog-polyfill#stacking-context
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-neutral-lighter);
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  @media print {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    width: max-content !important;
    --modal-padding: 0 !important;
    --modal-margin: 0 !important;
  }

  &.fullScreen {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    --modal-padding: 0;
    --modal-margin: 0;
    border-radius: 0;
  }

  &.maxWidth {
    @include device-small {
      max-width: 778px;
    }
  }
}

.content {
  padding: var(--modal-padding);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
}

.bodyMaxWidth {
  max-width: 424px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footerMaxWidth {
  max-width: 284px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  min-width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: var(--modal-padding);
  right: var(--modal-padding);
  margin: 0;
  padding: 0;

  &.buttonFullScreen {
    top: 22px;
    right: 24px;
    & svg {
      fill: var(--color-text-dark);
    }

    @include device-small {
      top: var(--modal-padding);
      right: var(--modal-padding);
    }
  }
}

.closeIcon {
  fill: var(--color-text-lighter);
}

.sticky {
  position: sticky;
  top: 0;
  padding-top: var(--modal-padding);
  padding-bottom: var(--modal-padding);
  z-index: 1;
  .closeButton.buttonFullScreen {
    right: 0;
  }

  .closeButton {
    right: 0;
  }
}

.header {
  background-color: var(--color-neutral-lighter);
}
