.card {
  background-color: var(--color-neutral-lighter);
  border: 1px solid var(--color-neutral-dark);
  border-radius: 3px;
  overflow: hidden;
}

.body {
  padding: var(--size-spacing-04);
}
