.updatesPointer {
  width: 6px;
  height: 6px;
  background-color: var(--color-interaction-base);
  margin-right: var(--size-spacing-02);
  border-radius: 50%;
}

.timelineUpdatesCount {
  display: flex;
  align-items: center;
}

