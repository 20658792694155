@import '../../styles/breakpoints';

.logo {
  padding: var(--size-spacing-02);
  border: 1px solid var(--color-neutral-dark);
  border-radius: 4px;
  background-color: var(--color-white);
  object-fit: contain;
  aspect-ratio: 1;
}
