@import '../../styles/breakpoints';

.owner {
  margin: 0;
}

.title {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: var(--size-spacing-05);

  @include device-large {
    display: none !important;
  }
}

.summary {
  font-size: var(--font-size-02);
  line-height: var(--font-size-05);
  font-weight: var(--font-weight-light);
  margin: 0;
  color: var(--color-grey-400);
}

.summary:first-letter {
  text-transform: capitalize;
}

.joinPageLink {
  white-space: nowrap;
}

.avatar {
  align-self: flex-start;
}
