@import '../../styles/breakpoints';

.totalsSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.totaliserContainer {
  display: flex;
  gap: var(--size-spacing-05);
}

.totaliser {
  width: 96px;
}

.label {
  background-color: var(--color-neutral-400);
  padding: var(--size-spacing-02) var(--size-spacing-03);
  border-radius: 4px;
  width: fit-content;

  @include device-large {
    align-self: center;
  }
}
