@import '../../styles/breakpoints';

$colors: (
  'facebook': #365899,
  'twitter': #000000,
  'email': #464646,
  'whatsapp': #25d366,
  'messenger': #0084ff,
  'linkedin': #0077b5,
  'copy': #999999,
  'sms': #464646,
  'qrCode': #464646,
  'instagram': #ff0069,
  'snapchat': #fffc00,
  'print': #464646,
  'tiktok': #000000,
);

.socialButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border-bottom: 1px solid var(--color-neutral-base);
  gap: var(--size-spacing-06);
  text-decoration: none;
  color: var(--color-text-dark);
  flex-direction: column;
  gap: 0;
  border-bottom: none;
  width: 70px;

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 60px;
    background-color: transparent;

    background-color: unset;
    margin-bottom: var(--size-spacing-03);
    width: 48px;
    height: 48px;

    @each $name, $value in $colors {
      &.#{$name} {
        background-color: $value;
      }
    }
  }

  .icon {
    @each $name, $value in $colors {
      &.#{$name} {
        fill: var(--color-neutral-lighter);
      }
    }
  }
}
