.container {
  position: relative;
  border-bottom: 1px solid var(--color-neutral-600);
  overflow: hidden;
  padding: var(--size-spacing-07);
  text-align: center;
  background-color: var(--color-white);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
